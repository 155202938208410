/*Variables*/
$golden-ratio: 1.61803398875;
$ra-border: rgb(226, 226, 226);
$color-size: 40px;

$font-family: 'Roboto', sans-serif;
$headings-font-family: 'Roboto', sans-serif;

$font-family-sans-serif: $font-family;
$font-family-monospace: $font-family;
$font-family-base: $font-family;

$font-weight-base: 300;

$background-header-color: #000000;
$header-link-color: #d5d5d5;
$headings-font-weight: 700;

$primary: #9a000c;
$secondary: #eeefef;
$link-color: #41C0f0;
$link-hover: #000000;

$btn-border-radius : 0!default;
$btn-border-radius-lg : 0!default;
$btn-border-radius-sm : 0!default;
$modal-content-border-radius: 0!default;
$alert-border-radius: 0!default;
$dropdown-border-radius: 0!default;

$input-border-radius: 0!default;
$input-border-radius-lg: 0!default;
$input-border-radius-sm: 0!default;

$zindex-dropdown : 1000 !default;
$zindex-sticky : 1020 !default;
$zindex-fixed : 1030 !default;
$zindex-modal-backdrop : 1040 !default;
$zindex-modal : 1050 !default;
$zindex-popover : 1060 !default;
$zindex-tooltip : 1070 !default;

$input-btn-focus-width : .0.5rem !default;
$input-btn-focus-color : none;
$input-btn-focus-box-shadow : none;

$images-path: '../images/';

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
) !default;

$featured-max-widths: (
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1340px
);

$features-img-height: (
    sm: 350px,
    md: 350px,
    lg: 700px,
    xl: 700px
);

$gallery-color: #000;

body, p {
    font-family: $font-family;

}
p, li{
    line-height: 1.5em;
}
a,a:hover,a:active {
    text-decoration: none!important;
}

i {
    font-style: italic;
}

input[type="submit"]{
    cursor: pointer;
}

h1,h2,h3,h4 {
    font-weight: 700;
}
/*
blockquote {
    padding: 20px;
    font-size: 1.2em;
    color: rgba($primary,0.6);
    position: relative;
    padding-right: 60px;
    background-color: rgba(grey, 0.05);
    font-style: italic;
    &:after {
        content: '\f10e';
        font-size: 40px;
        font-family: FontAwesome;
        color: rgba($primary, 0.6);
        display: block;
        position: absolute;
        top: 10px;
        right: 15px;
    }
}
*/

@mixin social-icon ($pos-x,$pos-y) {
    display: inline-block;
    text-indent: -9999px;
    width: 26px;
    height: 26px;
    background-image: url('../images/redes-sociales.svg');
    background-repeat: no-repeat;
    background-position: $pos-x $pos-y;
}

@mixin generic-icon($width, $height, $pos-x, $pos-y, $top, $position, $position-value, $padding) {
    
    position: relative;
    padding-#{$position}: $padding;

    &:after {
        content: "";
        width: $width;
        height: $height;
        position: absolute;
        top: $top;
        #{$position}: $position-value;
        display: block;
        background-image: url('#{$images-path}sprite.svg');
        background-repeat: no-repeat;
        background-position: $pos-x $pos-y;
    }
}

@mixin font-size-screen ($size-xs,$size-sm,$size-md,$size-lg,$size-xl) {

    font-size: $size-xs;

    @include media-breakpoint-up('sm') {
        font-size: $size-sm;
    }
    @include media-breakpoint-up('md') {
        font-size: $size-md;
    }
    @include media-breakpoint-up('lg') {
        font-size: $size-lg;
    }
    @include media-breakpoint-up('xl') {
        font-size: $size-xl;
    }
}

@mixin font-size-screen-full ($size-xs, $size-sm, $size-md, $size-lg, $size-xl, $size-xxl) {
    font-size: $size-xs;
    @include media-breakpoint-up('sm') {
        font-size: $size-sm;
    }
    @include media-breakpoint-up('md') {
        font-size: $size-md;
    }
    @include media-breakpoint-up('lg') {
        font-size: $size-lg;
    }
    @include media-breakpoint-up('xl') {
        font-size: $size-xl;
    }
    @include media-breakpoint-up('xxl') {
        font-size: $size-xxl;
    }
}