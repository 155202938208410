@import "../bundles/adesignscalendar/css/fullcalendar/fullcalendar";

.container-calendarbig {
    padding-top: 2rem;
    position: relative;
    display: block!important;
    width: 100%!important;
    min-height: 100vh;
    position: relative!important;
}

#calendar-holder {
    table {
        thead {
            th {
                height: 1px;
            }
        }
    }
}

#monthSelector {
    display: none;
    position: absolute;
    z-index: 1;
    margin-left: 120px;
    width: 185px;

    &:focus {
        border: 1px solid #ced4da;
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}

.fc-widget-header, .fc-widget-content {
    border-color: rgba($ra-border, 0.5);
}

.fc-today {
    background-color: rgba($primary, 0.1)!important;
}

.fc-button-today {
    .svg-inline--fa {
        margin-left: 5px;
    }
}

.calendar-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9997;
    &:after {
        content: '';
        position: absolute;
        background-color: rgba(255,255,255,0.98);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 9998;
    }
    &:before {
        content: '';
        width: 100px;
        height: 100px;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #fff;
        border-left: 3px solid $primary;
        top: 20%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: block;
        z-index: 9999;
        animation: raloading 1s ease-in-out 0s infinite;
    }
}

@keyframes raloading {
    0% {
        transform: translate(-50%,-50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%,-50%) rotate(360deg);
    }
}

.calendar-loading-inner {
    position: absolute;
    width: 90px;
    height: 90px;
    background: #fff url('../images/ra-logo-cabecera.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 9999;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba($primary, 0.3);
}

.fc-widget-header, .fc-widget-content {
    border-color: #fff;
    border-width: 2px!important;
}

.fc-grid .fc-day-number{
    float: none;
    font-weight: 700;
    color: #000;
    text-align: center;
    padding: 17px 0px;
    font-size: 22px;
    .ra-day-week{
        font-weight: 300;
        color: grey;
        padding-right: 6px;
        text-transform: uppercase;
        font-weight: 300;
    }
}

.fc-event-inner {

    //border-top: 2px solid red;
    //border-bottom: 2px solid red;

    border: none;
    box-shadow: none;
    background-color: #fff;
    color: #000;
    padding: 0px 5px;
}

.fc-event-hori {
    border: none;
    margin-bottom: 8px;
}

.fc-event-skin {
    background-color: #fafafa;
    border-radius: 0;
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
}

.fc-event-color {
    width: 17px;
    height: 100%;
    display: block;
    //border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 4px;
    //border: 1px solid $ra-border;
    //box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
}

.fc-event {
    padding: 0px 8px;
}
.fc-event-inner {
    position: relative;
    padding: 3px 3px 3px 20px;
}

.fc-state-disabled {
    display: none;
}

.fc-widget-content {
    background-color: #f1f1f1;
}

.fc-event-title{
    font-size: 1.2em;
    color: #919191;
    .hour {
        display: inline-block;
        vertical-align: top;
        color: #000;
        font-weight: 700;
        padding-right: 8px;
    }
    .language {
        color: #858585;
        padding-right: 8px;
    }
    .free {
        color: red;
        font-weight: 700;
    }
}

.fc-other-month {
    //position: relative;
    background-color: rgba(241, 241, 241,0.3);
}

.no-actual-month {
    opacity: 0.6;
}
//Calendario mini
#calendario {
    .ng-comp-main-div {
        border:none;
    }
    .ng_cal_month_group_td {
        border: solid #fff 1px;
        background-color: #f1f1f1;
    }
    .ng_cal_out_of_range {
        background-color:#fbfbfb;
        opacity: 0.4;
    }
    .ng_cal_header_th {
        background-color: #fff;
        font-weight: 700;
    }
    .ng_cal_dates_tr {
        td {
            text-align: center;
        }
    }
    .ng_cal_bottom_bar {
        display: none;
    }
    .ng_cal_selectable {
        background-color: rgba($primary,0.3);
        color: #fff;
    }
    .ng_cal_selected_date {
        background: $primary;
        color: #fff;
    }
    .ng-comp-main-div {
        background-color: #fff;
    }
}

.ng-input-button-container {
    display: none;
}

.ng_cal_date_off {
    background-color: #f74f4f;
    color: black!important;
}

[id^="calendar_buttonsng_calendar_"] {
    display: none;
}

#calendarioContainer{
    padding-top: 30px;
}

.dia {
    display: inline-block;
}

