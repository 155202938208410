.container-main {
    padding-top: 40px;
    padding-bottom: 40px;
}

.section-header {
    //padding-top: 2rem;
    //border-bottom: 1px solid $ra-border;
    .visita-color {
        position: relative;
        left: 10px;
        top: 5px;
    }

    p {
        font-size: 17px;
        color:#676767;
        margin-bottom: 0;
    }
}

.section-tit {
    font-size: 26px;
    line-height: 26px;
    small {
        font-weight: 400;
        font-size: 1.2rem;
        display: block;
    }
}

.subsection-tit {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 20px;
}

.ra-actions{
    display: inline-block;
    .fa {
        margin-right: 5px;
    }
    li {
        display: inline-block;
    }
}

.ra-actions-block {
    margin-top: 40px;
    li {
        display: block;
        padding-bottom: 5px;
    }
}

.table {
    thead {
        th {
            font-weight: 700;
        }
    }
    tbody {
        td {
            vertical-align: middle;

        }
        tr {
            &:hover {
                background-color: #fafafa;
            }
        }
    }
    .table-name {
        display: inline-block;
        line-height: $color-size;
        font-weight: 400;
    }
    .fa-edit {
        display: inline-block;
        margin-right: 5px;
        color: rgba(0,0,0,0.7);
    }
}

.visita-color {
    display: block;
    width: $color-size;
    height: $color-size;
    border: 1px solid #000;
    //margin-right: #{$golden-ratio}em;
    //box-shadow: inset 0px 0px 3px rgba(0,0,0,0.4)
}

.tit-visita-color {
    width: 100%;
    height: 16px;
    display: block;
    margin-bottom: 10px;
}

.table-tipovisita {
    thead {
        display: none;
    }
}

.colorpicker-component {
    display: block!important;
    label {
        display: block!important;
    }
}
.colorpicker-element .input-group-addon {
    display: inline-block;
    vertical-align: top;
    i {
        width: 38px!important;
        height: 38px!important;
        vertical-align: top!important;
        position: relative!important;
        top: 0px;
    }
}

.form-dias {
    padding-top: 2.25rem;
}

.form-buttons {
    margin-top: 30px;
    .buttons {
        padding-top: 30px;
        border-top: 5px solid #e9ecef;
    }
}

.form-tit {
    font-size: 1.3rem;
    font-weight: 700;
}

.form-control-list {
    border: none;
    padding-left: 0;
    label {
        margin-right: 10px;
        margin-left: 2px;
    }
}

.horarios {
    li {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        padding: 0px 10px;
        input[type="checkbox"] {
            position: relative;
            top: 3px;
        }
        label {
            display: none;
        }
        &:last-child{
            display: block;
            text-align: left;
            padding-left: 0px;
        }
        &:first-child {
            padding-left: 0px;
            text-align: left;
            label {
                width: 60px;
                display: inline-block;
                text-align: left;
            }
            input[type="checkbox"] {
                top: 0px;
                margin-left: calc( (100% - 60px) / 2);
                position: relative;
                transform: translateX(-50%);
            }
        }
    }
}

.btn-validate {
    margin-top: calc(25px + .5rem);
}

.op-message {
    background-color: #fafafa;
    padding: #{$golden-ratio}em;
    margin-bottom: #{$golden-ratio}em;
    .alert {
        p,ul {
            margin: 0;
            padding: 0;
            font-size: .9rem;
            line-height: .9rem;
        }
        ul {
            list-style-type: circle;
            margin-top: .5rem;
            padding-left: #{$golden-ratio}em;
        }
    }
    em {
        font-size: #{$golden-ratio}em;
        color: $primary;
        font-weight: 700;
    }
}

#date-picker-container {
    .bootstrap-datetimepicker-widget table td,
    .bootstrap-datetimepicker-widget table th {
        border-radius: 0px;
    }
    .bootstrap-datetimepicker-widget table td span {
        border-radius: 0px
    }
    .bootstrap-datetimepicker-widget table td span.active {
        background-color: $primary;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
    }
    .bootstrap-datetimepicker-widget table td.active,
    .bootstrap-datetimepicker-widget table td.active:hover {
        background-color: $primary;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
    }
    
    .bootstrap-datetimepicker-widget table td span.old {
        color: #fafafa
    }
    
    .bootstrap-datetimepicker-widget table td span.disabled,
    .bootstrap-datetimepicker-widget table td span.disabled:hover {
        background: none;
        color: #fafafa;
        cursor: not-allowed
    }
    .bootstrap-datetimepicker-widget table td.day {
        height: 30px;
        line-height: 30px;
        width: 30px
    }
    .bootstrap-datetimepicker-widget table {
        font-size: .9rem;
    }
    .bootstrap-datetimepicker-widget table td.disabled,
    .bootstrap-datetimepicker-widget table td.disabled:hover {
        background: none;
        color: rgb(182, 182, 182);
        cursor: not-allowed
    }
    .bootstrap-datetimepicker-widget table td.today:before {
        border-bottom-color: $primary;

    }
    .table-condensed {
        thead{
            th {
                padding-top: 4px;
                padding-bottom: 4px;
                text-transform: capitalize;
                font-weight: 700;
            }
        }
    }
}

#invalido, #valido {
    font-size: 0.9rem;
}
#invalido {
    color: red;
}
#valido {
    color: green;
}