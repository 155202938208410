.login {
    width: 50%;
    max-width: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.login-brand {
    margin-bottom: #{$golden-ratio}em;
}

.login-form {
    border-top: 1px solid rgb(226, 226, 226);
    border-bottom: 1px solid rgb(226, 226, 226);
    padding: 2rem;
}
