.header {
    background-color: $secondary;
}

.main-menu{
    .nav-item {
        padding-left: #{$golden-ratio/2}em;
    }
    .nav-link {
        &:hover {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: rgba(0,0,0,0.4);
                position: absolute;
                bottom: 0px;
                left: 0;
                display: block;
            }
        }
    }
    .active {
        color: $primary!important;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $primary!important;
            position: absolute;
            bottom: 0px;
            left: 0;
            display: block;
        }
    }
}

.footer-container{
    margin-top: 10rem;
    padding-top: 2rem;
    border-top: 1px solid $ra-border;
}